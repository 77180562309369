.container {
  display: flex;
  flex-direction: column;
}

a:hover,
a:visited,
a:link,
a:active,
.a:hover,
.a:visited,
.a:link,
.a:active {
  text-decoration: none;
}

.gamelink {}

.topbar {
  display: flex;
  justify-content: space-between;
  background-color: #1e1e1e;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 6px;
}

.navcontainer {
  display: flex;
  justify-content: center;
  align-self: stretch;
  margin-bottom: -3px;
}

#rightcontentDiv {
  display: flex;
  align-self: stretch;
  justify-content: stretch;
}

.hamburger {
  display: none;
}

.navbutton,
.hamburger {
  border: none;
  background-color: #1e1e1e;
  color: #eaeaea;
  font-weight: 900;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
}

.focused {
  background-color: #eaeaea;
  color: #7c0000;
}

.navbutton:hover {
  background-color: white;
  color: #7c0000;
}

.navbutton:active {
  background-color: #eaeaea;
  color: #7c0000;
}

.logo_and_title {
  display: flex;
  gap: 20px;
}

.title {
  font-family: 'Poppins', sans-serif;
  font-size: 23px;
  color: white;
  padding: 0px;
  margin: 0px;
  font-style: italic;
  font-weight: 900;
  align-self: center;
}

.footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  background-color: #222222;
  color: white;
  text-align: center;
}

.footertext {
  color: white;
}

.privacylink {
  margin: 0px;
}

.gamescontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gamesheader {
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
}

@media only screen and (min-width: 841px) {
  .gameplaceholder div {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 50%;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s, opacity 0.25s linear;
    font-family: 'Poppins', sans-serif;
    font-weight: 900;
    font-size: 40px;
    width: 100%;
    color: black;
    text-align: center;
  }

  .gamescontent {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-left: 25px;
    padding-right: 25px;
    gap: 20px;
    padding-top: 2.5%;
    padding-bottom: 10%;
  }

  .gamescontent>* {
    flex: 25% 0 0;
  }

  .gameplaceholder {
    position: relative;
    height: 0;
    width: 25%;
    padding-bottom: 25%;
  }
}

.gameplaceholder:hover .gameimg {
  cursor: pointer;
  opacity: 0.5;
}

.gameplaceholder:hover div {
  visibility: visible;
  opacity: 1;
}

.gameimg {
  max-width: 100%;
  height: auto;
}

@media only screen and (max-width: 840px) {
  #rightcontentDiv {
    display: none;
  }

  #rightcontent {
    flex-direction: column;
    padding-top: 3px;
  }

  .topbar {
    flex-direction: column;
    padding: 0;
    padding-bottom: 6px;
    max-width: 100%;
    height: auto;
  }

  .logo_and_title {
    max-width: 100%;
    height: auto;
  }

  .leftcontent {
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
    max-width: 100%;
    height: auto;
  }

  .hamburger,
  a.hamburger {
    display: flex;
    justify-content: stretch;
    align-self: center;
    padding: 0;
  }

  .gamescontent {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 5px;
    padding-right: 5px;
    gap: 5px;
    padding-top: 2.5%;
    padding-bottom: 10%;
  }

  .gamescontent>* {
    flex: 25% 0 0;
  }

  .gameplaceholder div {
    display: flex;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 900;
    width: 100%;
    color: black;
    text-align: center;
  }
}

.formcontainer {
  padding: 5%;
  padding-left: 25%;
  padding-right: 25%;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.topformContainer {
  display: flex;
  gap: 15px;
}

.dividers {
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.m {
  width: 100%;
}

.submitform {
  width: 100px;
}

@media only screen and (max-width: 840px) {
  .topformContainer {
    flex-direction: column;
  }
}

/* .form {
  border-style: solid;
  padding: 10px;
  border-color: lightgray;
} */

.privacytext {
  padding: 70px;
}